import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contact-form/ContactForm'

import './contact.scss'

const ContactPage = () => {

  const data = useStaticQuery(graphql`
    query ContactQuery {
      desktop: file(relativePath: { eq: "components/layout/img/background-data.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="WRR Enterprises - Contact Us" />
      <BackgroundImage
        Tag="main"
        fluid={imageData}
        backgroundColor={`#000`}
      >
        <div className="contact-form">
            <h2>Contact us</h2>
            <p>You want to get more out of your analytics; deeper insight, better business intelligence.</p>
            <p>SkyLytics can help you. Drop us a line and let's see where the conversation takes us.</p>
            <ContactForm />
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export default ContactPage 
